<template>
  <div style="position: relative; height: calc(100vh - 40px)">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <el-date-picker
        v-model="search.date"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        @change="handleUserDate"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input
        placeholder="请输入手机号"
        @change="handleUserDate"
        v-model="search.phone"
      ></el-input>
    </div>

    <div class="data_compare_g2" v-if="search.phone">
      <div class="data_compare_g2_model">
        <div class="data_compare_g2_model_son">
          <div style="display: flex; align-items: center">
            <p>激活趣宝盒数据</p>
            <!-- <el-button
              type="text"
              v-if="search.phone"
              style="margin-left: 5px"
              @click="handleDetail('box')"
              >查看详情</el-button
            > -->
          </div>
          <el-date-picker
            v-model="search.model.boxdate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="handleUserDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div id="container" v-if="search.phone"></div>
        <div class="container_empty" v-else>请输入手机号进行查询</div>
      </div>

      <div class="data_compare_g2_model">
        <div class="data_compare_g2_model_son">
          <div style="display: flex; align-items: center">
            <p>推广用户数据</p>
            <el-button
              type="text"
              v-if="search.phone"
              style="margin-left: 5px"
              @click="handleDetail('user')"
              >查看详情</el-button
            >

            <el-dialog title="推广用户数据" :visible.sync="UserVisible">
              <div>
                <el-button
                  @click="exportToExcel('user')"
                  type="primary"
                  style="margin-left: 10px"
                  >导 出</el-button
                >
              </div>
              <el-table :data="userData">
                <el-table-column
                  type="index"
                  label="序号"
                  width="80"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.$index + (currentPage - 1) * pageSize + 1
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="手机号"></el-table-column>
                <el-table-column prop="realName" label="姓名"></el-table-column>
                <el-table-column prop="userStatus" label="状态">
                  <template slot-scope="scope">
                    {{
                      scope.row.userStatus == "0"
                        ? "注销"
                        : scope.row.userStatus == "1"
                        ? "正常"
                        : scope.row.userStatus == "2"
                        ? "封禁"
                        : ""
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="regTime" label="注册时间">
                </el-table-column>
                <el-table-column prop="authTime" label="实名时间">
                </el-table-column>
                <el-table-column prop="driverTime" label="车主认证时间">
                </el-table-column>
                <el-table-column prop="cancelTime" label="注销时间">
                </el-table-column>
              </el-table>
            </el-dialog>
          </div>
          <el-date-picker
            v-model="search.model.boxdate2"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="handleUserDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div id="container2" v-if="search.phone"></div>
        <div class="container_empty" v-else>请输入手机号进行查询</div>
      </div>

      <div class="data_compare_g2_model" style="height: 434px">
        <div class="data_compare_g2_model_son">
          <div style="display: flex; align-items: center">
            <p>RMB数据</p>
            <el-button
              type="text"
              v-if="search.phone"
              style="margin-left: 5px"
              @click="handleDetail('rmb')"
              >查看详情</el-button
            >

            <el-dialog title="RMB数据" :visible.sync="RMBVisible">
              <div>
                <el-select
                  v-model="searchType"
                  placeholder="请选择查询类别"
                  @change="handleChangeSearchType('rmb')"
                >
                  <el-option label="全部" value="0"></el-option>
                  <el-option label="充值提现" value="1"></el-option>
                  <el-option label="车费" value="2"></el-option>
                  <el-option label="回馈" value="3"></el-option>
                  <el-option label="广告" value="4"></el-option>
                  <el-option label="任务" value="5"></el-option>
                  <el-option label="推广" value="6"></el-option>
                </el-select>

                <el-button
                  @click="exportToExcel('rmb')"
                  type="primary"
                  style="margin-left: 10px"
                  >导 出</el-button
                >
              </div>
              <el-table :data="RMBData">
                <el-table-column
                  type="index"
                  label="序号"
                  width="80"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.$index + (currentPage - 1) * pageSize + 1
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="账单时间"
                ></el-table-column>
                <el-table-column prop="amount" label="金额">
                  <template slot-scope="scope">
                    {{ scope.row.isIncome ? "+" : "-" }}{{ scope.row.amount }}
                  </template>
                </el-table-column>
                <el-table-column prop="fee" label="手续费"> </el-table-column>

                <el-table-column prop="amountTotal" label="总额">
                </el-table-column>
                <el-table-column prop="afterAmount" label="交易后金额">
                </el-table-column>
              </el-table>

              <Pagination
                v-show="pageLength >= 0"
                :total="pageLength"
                :limit.sync="pageSize"
                @pagination="(data) => handlePageChange(data, 'rmb')"
                :page-sizes="pageSizes"
              />
            </el-dialog>
          </div>
          <el-date-picker
            v-model="search.model.boxdate3"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="handleUserDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div id="container3" v-if="search.phone"></div>

        <div class="container_empty" v-else>请输入手机号进行查询</div>
        <div style="margin-left: 10px">
          <el-button @click="handleRMBOperation(1)">增加RMB金额</el-button>
          <el-button @click="handleRMBOperation(2)" type="danger" plain
            >扣除RMB金额</el-button
          >

          <el-dialog
            title="增加RMB金额"
            :visible.sync="RMBdialogFormVisibleAdd"
          >
            <el-form :model="form">
              <el-form-item label="枚举类型" :label-width="formLabelWidth">
                <el-select
                  v-model="RMBoptions.RMBSelectValue"
                  placeholder="请选择枚举类型"
                  filterable
                >
                  <el-option
                    v-for="item in RMBoptions.option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="手续费" :label-width="formLabelWidth">
                <el-input-number
                  v-model="RMBoptions.fee"
                  :precision="2"
                  :step="0.1"
                  type="number"
                ></el-input-number>
              </el-form-item>

              <el-form-item label="金额" :label-width="formLabelWidth">
                <el-input-number
                  v-model="RMBoptions.amount"
                  :precision="2"
                  :step="0.1"
                  type="number"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input
                  v-model="RMBoptions.detail"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="RMBdialogFormVisibleAdd = false"
                >取 消</el-button
              >
              <el-button type="primary" @click="handleRMBOperationTrue(true)"
                >确 定</el-button
              >
            </div>
          </el-dialog>

          <el-dialog
            title="扣除RMB金额"
            :visible.sync="RMBdialogFormVisibleSubtract"
          >
            <el-form :model="form">
              <el-form-item label="枚举类型" :label-width="formLabelWidth">
                <el-select
                  v-model="RMBoptions.RMBSelectValue"
                  placeholder="请选择枚举类型"
                  filterable
                >
                  <el-option
                    v-for="item in RMBoptions.option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="手续费" :label-width="formLabelWidth">
                <el-input-number
                  v-model="RMBoptions.fee"
                  :precision="2"
                  :step="0.1"
                  type="number"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="金额" :label-width="formLabelWidth">
                <el-input-number
                  v-model="RMBoptions.amount"
                  :precision="2"
                  :step="0.1"
                  type="number"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input
                  v-model="RMBoptions.detail"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="RMBdialogFormVisibleSubtract = false"
                >取 消</el-button
              >
              <el-button type="primary" @click="handleRMBOperationTrue(false)"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>

      <div class="data_compare_g2_model" style="height: 434px">
        <div class="data_compare_g2_model_son">
          <div style="display: flex; align-items: center">
            <p>CPLE积分数据</p>
            <el-button
              type="text"
              v-if="search.phone"
              style="margin-left: 5px"
              @click="handleDetail('cpleScore')"
              >查看详情</el-button
            >

            <el-dialog title="CPLE积分数据" :visible.sync="cpleScoreVisible">
              <div>
                <el-button
                  @click="exportToExcel('cpleScore')"
                  type="primary"
                  style="margin-left: 10px"
                  >导 出</el-button
                >
              </div>
              <el-table :data="cpleScoreData">
                <el-table-column
                  type="index"
                  label="序号"
                  width="80"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.$index + (currentPage - 1) * pageSize + 1
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="账单时间"
                ></el-table-column>
                <el-table-column prop="amount" label="金额">
                  <template slot-scope="scope">
                    {{ scope.row.isIncome ? "+" : "-" }}{{ scope.row.amount }}
                  </template>
                </el-table-column>
                <el-table-column prop="fee" label="手续费"></el-table-column>
                <el-table-column prop="amountTotal" label="总额">
                </el-table-column>
                <el-table-column prop="afterAmount" label="交易后金额">
                </el-table-column>
              </el-table>

              <Pagination
                v-show="pageLength >= 0"
                :total="pageLength"
                :limit.sync="pageSize"
                @pagination="(data) => handlePageChange(data, 'cpleScore')"
                :page-sizes="pageSizes"
              />
            </el-dialog>
          </div>
          <el-date-picker
            v-model="search.model.boxdate4"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="handleUserDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div id="container4" v-if="search.phone"></div>
        <div class="container_empty" v-else>请输入手机号进行查询</div>

        <div style="margin-left: 10px">
          <el-button @click="handleCPLEOperation(1)">增加CPLE积分</el-button>
          <el-button @click="handleCPLEOperation(2)" type="danger" plain
            >扣除CPLE积分</el-button
          >

          <el-dialog
            title="增加CPLE积分"
            :visible.sync="CPLEdialogFormVisibleAdd"
          >
            <el-form :model="form">
              <el-form-item label="枚举类型" :label-width="formLabelWidth">
                <el-select
                  v-model="CPLEoptions.CPLESelectValue"
                  placeholder="请选择枚举类型"
                  filterable
                >
                  <el-option
                    v-for="item in CPLEoptions.option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="金额" :label-width="formLabelWidth">
                <el-input-number
                  v-model="CPLEoptions.amount"
                  :precision="2"
                  :step="0.1"
                  type="number"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input
                  v-model="CPLEoptions.detail"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="CPLEdialogFormVisibleAdd = false"
                >取 消</el-button
              >
              <el-button type="primary" @click="handleCPLEOperationTrue(true)"
                >确 定</el-button
              >
            </div>
          </el-dialog>

          <el-dialog
            title="扣除CPLE积分"
            :visible.sync="CPLEdialogFormVisibleSubtract"
          >
            <el-form :model="form">
              <el-form-item label="枚举类型" :label-width="formLabelWidth">
                <el-select
                  v-model="CPLEoptions.CPLESelectValue"
                  placeholder="请选择枚举类型"
                  filterable
                >
                  <el-option
                    v-for="item in CPLEoptions.option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="金额" :label-width="formLabelWidth">
                <el-input-number
                  v-model="CPLEoptions.amount"
                  :precision="2"
                  :step="0.1"
                  type="number"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input
                  v-model="CPLEoptions.detail"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="CPLEdialogFormVisibleAdd = false"
                >取 消</el-button
              >
              <el-button type="primary" @click="handleCPLEOperationTrue(false)"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>

      <div class="data_compare_g2_model" style="height: 434px">
        <div class="data_compare_g2_model_son">
          <div style="display: flex; align-items: center">
            <p>转出额度数据</p>
            <el-button
              type="text"
              v-if="search.phone"
              style="margin-left: 5px"
              @click="handleDetail('quota')"
              >查看详情</el-button
            >

            <el-dialog title="转出额度数据" :visible.sync="QuotaVisible">
              <div>
                <el-select
                  v-model="searchType"
                  placeholder="请选择查询类别"
                  @change="handleChangeSearchType('quota')"
                >
                  <el-option label="全部" value="0"></el-option>
                  <el-option label="收入" value="1"></el-option>
                  <el-option label="支出" value="2"></el-option>
                </el-select>
                <el-button
                  @click="exportToExcel('quota')"
                  type="primary"
                  style="margin-left: 10px"
                  >导 出</el-button
                >
              </div>
              <el-table :data="QuotaData">
                <el-table-column
                  type="index"
                  label="序号"
                  width="80"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.$index + (currentPage - 1) * pageSize + 1
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="账单时间"
                ></el-table-column>
                <el-table-column prop="amount" label="金额">
                  <template slot-scope="scope">
                    {{ scope.row.isIncome ? "+" : "-" }}{{ scope.row.amount }}
                  </template>
                </el-table-column>
                <el-table-column prop="fee" label="手续费"> </el-table-column>
                <el-table-column prop="amountTotal" label="总额">
                </el-table-column>
                <el-table-column prop="afterAmount" label="交易后金额">
                </el-table-column>
              </el-table>

              <Pagination
                v-show="pageLength >= 0"
                :total="pageLength"
                :limit.sync="pageSize"
                @pagination="(data) => handlePageChange(data, 'quota')"
                :page-sizes="pageSizes"
              />
            </el-dialog>
          </div>
          <el-date-picker
            v-model="search.model.boxdate5"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="handleUserDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div id="container5" v-if="search.phone"></div>
        <div class="container_empty" v-else>请输入手机号进行查询</div>

        <div style="margin-left: 10px">
          <el-button @click="handleQuotaOperation(1)">增加额度</el-button>
          <el-button @click="handleQuotaOperation(2)" type="danger" plain
            >扣除额度</el-button
          >

          <el-dialog title="增加额度" :visible.sync="QuotadialogFormVisibleAdd">
            <el-form :model="form">
              <el-form-item label="枚举类型" :label-width="formLabelWidth">
                <el-select
                  v-model="Quotaoptions.QuotaSelectValue"
                  placeholder="请选择枚举类型"
                  filterable
                >
                  <el-option
                    v-for="item in Quotaoptions.option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="手续费" :label-width="formLabelWidth">
                <el-input-number
                  v-model="Quotaoptions.fee"
                  :precision="2"
                  :step="0.1"
                  type="number"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="金额" :label-width="formLabelWidth">
                <el-input-number
                  v-model="Quotaoptions.amount"
                  :precision="2"
                  :step="0.1"
                  type="number"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input
                  v-model="Quotaoptions.detail"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="QuotadialogFormVisibleAdd = false"
                >取 消</el-button
              >
              <el-button type="primary" @click="handleQuotaOperationTrue(true)"
                >确 定</el-button
              >
            </div>
          </el-dialog>

          <el-dialog
            title="扣除额度"
            :visible.sync="QuotadialogFormVisibleSubtract"
          >
            <el-form :model="form">
              <el-form-item label="枚举类型" :label-width="formLabelWidth">
                <el-select
                  v-model="Quotaoptions.QuotaSelectValue"
                  placeholder="请选择枚举类型"
                  filterable
                >
                  <el-option
                    v-for="item in Quotaoptions.option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="手续费" :label-width="formLabelWidth">
                <el-input-number
                  v-model="Quotaoptions.fee"
                  :precision="2"
                  :step="0.1"
                  type="number"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="金额" :label-width="formLabelWidth">
                <el-input-number
                  v-model="Quotaoptions.amount"
                  :precision="2"
                  :step="0.1"
                  type="number"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input
                  v-model="Quotaoptions.detail"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="QuotadialogFormVisibleAdd = false"
                >取 消</el-button
              >
              <el-button type="primary" @click="handleQuotaOperationTrue(false)"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>

      <div class="data_compare_g2_model">
        <div class="data_compare_g2_model_son">
          <div style="display: flex; align-items: center">
            <p>CPLE趣宝盒上链数据</p>
            <!-- <el-button
              type="text"
              v-if="search.phone"
              style="margin-left: 5px"
              @click="handleDetail('pledge')"
              >查看详情</el-button
            > -->
          </div>
          <el-date-picker
            v-model="search.model.boxdate6"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="handleUserDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div id="container6" v-if="search.phone"></div>
        <div class="container_empty" v-else>请输入手机号进行查询</div>
      </div>
    </div>
  </div>
</template>

<script src="https://unpkg.com/@antv/g2@next/dist/g2.min.js"></script>
<script>
import { Chart } from "@antv/g2";
import dayjs from "dayjs";
import * as xlsx from "xlsx";
import * as echarts from "echarts";

import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { format_time_date } from "@/util/funBusiness";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: { Breadcrumb, Pagination },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          // 获取时间选择器的月份信息
          let timeday = time.getDate(); // 获取时间选择器的月份

          let curDate = new Date().getTime();
          let three = 180 * 24 * 3600 * 1000;
          let threeMonths = curDate - three;

          if (timeday >= 1 && timeday < 10) {
            timeday = "0" + timeday;
          }

          //小于指定时间都不可选
          return (
            time.getTime() > new Date().getTime() ||
            time.getTime() < threeMonths
          );
        },
      },
      BreadcrumbCon: [
        {
          name: "统计",
        },
        {
          name: "个人数据比对",
        },
      ], // 面包屑数据

      valueArea: "",
      options: [],

      isActive: true,
      isNotActive: false,

      searchType: "", // 详情查询类别

      RMBVisible: false,
      QuotaVisible: false,
      cpleScoreVisible: false,
      UserVisible: false,

      RMBData: [],
      QuotaData: [],
      cpleScoreData: [],
      userData: [],

      search: {
        date: [],
        phone: "", //13980208042
        model: {
          boxdate: [],
          boxdate2: [],
          boxdate3: [],
          boxdate4: [],
          boxdate5: [],
          boxdate6: [],
        },
      },

      data: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
      data6: [],

      form: {
        beginDate: "",
        endDate: "",
      },

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 50, 100, 500, 1000, 5000, 10000, 20000, 50000, 100000],

      RMBdialogFormVisibleAdd: false,
      RMBdialogFormVisibleSubtract: false,
      RMBoptions: {
        RMBSelectValue: "",
        option: [],
        fee: "",
        amount: "",
        detail: "",
      },
      CPLEdialogFormVisibleAdd: false,
      CPLEdialogFormVisibleSubtract: false,
      CPLEoptions: {
        CPLESelectValue: "",
        option: [],
        amount: "",
        detail: "",
      },
      QuotadialogFormVisibleAdd: false,
      QuotadialogFormVisibleSubtract: false,
      Quotaoptions: {
        QuotaSelectValue: "",
        option: [],
        fee: "",
        amount: "",
        detail: "",
      },
      formLabelWidth: "120px",

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  created() {
    this.getDefaultTime();
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");
  },

  methods: {
    handleUserDate(e) {
      this.form.beginDate = e[0];
      this.form.endDate = e[1];

      if (this.search.phone && this.search.date) {
        if (this.search.model.boxdate.length == 0) {
          this.boxAxios("all");
        } else {
          this.boxAxios("box");
        }

        if (this.search.model.boxdate2.length == 0) {
          this.userPromotionAxios("all");
        } else {
          this.userPromotionAxios("box");
        }

        if (this.search.model.boxdate3.length == 0) {
          this.RMBAxios("all");
        } else {
          this.RMBAxios("box");
        }

        if (this.search.model.boxdate4.length == 0) {
          this.CPLEscoreAxios("all");
        } else {
          this.CPLEscoreAxios("box");
        }

        if (this.search.model.boxdate5.length == 0) {
          this.QuotaAxios("all");
        } else {
          this.QuotaAxios("box");
        }

        if (this.search.model.boxdate6.length == 0) {
          this.PledgeAxios("all");
        } else {
          this.PledgeAxios("box");
        }
      }
    },

    // 时间转换格式
    format_time_date_compare(d) {
      if (d !== null) {
        var date = new Date(d);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d;
        var currentdate = y + "-" + m + "-" + d;
        return currentdate;
      }
    },

    // 请求 激活趣宝盒数据
    boxAxios(key) {
      showLoading();
      const opt = {
        url: reqUrl.getBoxCheckStatistics,
        method: "POST",
        params: JSON.stringify({
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime:
            key == "all" ? this.search.date[0] : this.search.model.boxdate[0],
          endTime:
            key == "all" ? this.search.date[1] : this.search.model.boxdate[1],
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.data = res.data.data;

            let dataDate = [];
            let num = [];
            this.data.map((item) => {
              dataDate.push(this.format_time_date_compare(item.checkDate));
              num.push(item.countNum);
            });

            this.isNotActive = true;
            hideLoading();
            if (this.data.length == 1) {
              var chartDom = document.getElementById("container");
              const option = {
                tooltip: {
                  trigger: "axis",
                },
                toolbox: {
                  show: true,
                  feature: {
                    dataZoom: {
                      yAxisIndex: "none",
                    },
                    dataView: { readOnly: false },
                    magicType: { type: ["line", "bar"] },
                    restore: {},
                    saveAsImage: {},
                  },
                },

                xAxis: {
                  type: "category",
                  boundaryGap: false,
                  data: dataDate,
                },
                yAxis: {
                  type: "value",
                  axisLabel: {
                    formatter: "{value}",
                  },
                },
                series: [
                  {
                    name: "用户推荐趣宝盒",
                    type: "line",
                    data: num,
                    markPoint: {
                      data: [{ type: "max", name: "Max" }],
                    },
                  },
                ],
              };

              var myChart = echarts.init(chartDom);
              myChart.setOption(option);
            } else {
              const chart = new Chart({
                container: "container",
                theme: "classic",
                autoFit: true,
                paddingBottom: 80,
                paddingRight: 40,
              });

              chart
                .line()
                .data(this.data)
                .transform({ type: "groupX" })
                .encode("x", (d) => this.format_time_date_compare(d.checkDate))
                .encode("y", "countNum")
                .axis("y", { title: "" })
                .encode("color", "symbol")
                .encode("shape", "smooth")
                .style("lineWidth", 2.5)
                .label({
                  text: "",
                  transform: [{ type: "overlapDodgeY" }],
                  style: {
                    fontSize: 10,
                  },
                });

              chart.render();
            }
          }
          if (res.data.code == 401 || res.data.code == 403) {
            this.$notify.error({
              title: "错误",
              message: "账号无权限，无法操作",
            });
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    // 请求 推广用户数据
    userPromotionAxios(key) {
      showLoading();
      const opt = {
        url: reqUrl.getUserCheckStatistics,
        method: "POST",
        params: JSON.stringify({
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime:
            key == "all" ? this.search.date[0] : this.search.model.boxdate2[0],
          endTime:
            key == "all" ? this.search.date[1] : this.search.model.boxdate2[1],
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.data2 = res.data.data;
            this.isNotActive = true;
            hideLoading();
            const chart = new Chart({
              container: "container2",
              theme: "classic",
              autoFit: true,
              paddingBottom: 80,
              paddingRight: 40,
            });

            chart
              .line()
              .data(this.data2)
              .transform({ type: "groupX" })
              .encode("x", (d) => this.format_time_date_compare(d.checkDate))
              .encode("y", "countNum")
              .axis("y", { title: "" })
              .encode("color", "symbol")
              .encode("shape", "smooth")
              .style("lineWidth", 2.5)
              .label({
                text: "",
                transform: [{ type: "overlapDodgeY" }],
                style: {
                  fontSize: 8,
                },
              });

            chart.interaction("tooltip", {
              // 设置 Tooltip 的位置
              position: "auto",
            });

            chart.render();
          } else if (res.data.code == 401 || res.data.code == 403) {
            this.$notify.error({
              title: "错误",
              message: "账号无权限，无法操作",
            });
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    // 请求 RMB数据
    RMBAxios(key) {
      showLoading();
      const opt = {
        url: reqUrl.getRMBCheckStatistics,
        method: "POST",
        params: JSON.stringify({
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime:
            key == "all" ? this.search.date[0] : this.search.model.boxdate3[0],
          endTime:
            key == "all" ? this.search.date[1] : this.search.model.boxdate3[1],
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.data3 = res.data.data;
            this.isNotActive = true;
            hideLoading();
            const chart = new Chart({
              container: "container3",
              theme: "classic",
              autoFit: true,
              paddingBottom: 80,
              paddingRight: 40,
            });

            chart
              .line()
              .data(this.data3)
              .transform({ type: "groupX" })
              .encode("x", (d) => this.format_time_date_compare(d.checkDate))
              .encode("y", "countNum")
              .axis("y", { title: "" })
              .encode("color", "symbol")
              .encode("shape", "smooth")
              .style("lineWidth", 2.5)
              .label({
                text: "",
                transform: [{ type: "overlapDodgeY" }],
                style: {
                  fontSize: 8,
                },
              });

            chart.render();
          } else if (res.data.code == 401 || res.data.code == 403) {
            this.$notify.error({
              title: "错误",
              message: "账号无权限，无法操作",
            });
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    // 请求 CPLE积分数据
    CPLEscoreAxios(key) {
      showLoading();
      const opt = {
        url: reqUrl.getCPLECheckStatistics,
        method: "POST",
        params: JSON.stringify({
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime:
            key == "all" ? this.search.date[0] : this.search.model.boxdate4[0],
          endTime:
            key == "all" ? this.search.date[1] : this.search.model.boxdate4[1],
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.data4 = res.data.data;
            this.isNotActive = true;
            hideLoading();
            const chart = new Chart({
              container: "container4",
              theme: "classic",
              autoFit: true,
              paddingBottom: 80,
              paddingRight: 60,
              paddingLeft: 60,
            });

            chart
              .line()
              .data(this.data4)
              .transform({ type: "groupX" })
              .encode("x", (d) => this.format_time_date_compare(d.checkDate))
              .encode("y", "countNum")
              .axis("y", { title: "" })
              .encode("color", "symbol")
              .encode("shape", "smooth")
              .style("lineWidth", 2.5)
              .label({
                text: "",
                transform: [{ type: "overlapDodgeY" }],
                style: {
                  fontSize: 8,
                },
              });

            chart.render();
          } else if (res.data.code == 401 || res.data.code == 403) {
            this.$notify.error({
              title: "错误",
              message: "账号无权限，无法操作",
            });
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    // 请求 转出额度
    QuotaAxios(key) {
      showLoading();
      const opt = {
        url: reqUrl.getQuotaCheckStatistics,
        method: "POST",
        params: JSON.stringify({
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime:
            key == "all" ? this.search.date[0] : this.search.model.boxdate5[0],
          endTime:
            key == "all" ? this.search.date[1] : this.search.model.boxdate5[1],
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.data5 = res.data.data;
            this.isNotActive = true;
            hideLoading();
            const chart = new Chart({
              container: "container5",
              theme: "classic",
              autoFit: true,
              paddingBottom: 80,
              paddingRight: 60,
              paddingLeft: 60,
            });

            chart
              .line()
              .data(this.data5)
              .transform({ type: "groupX" })
              .encode("x", (d) => this.format_time_date_compare(d.checkDate))
              .encode("y", "countNum")
              .axis("y", { title: "" })
              .encode("color", "symbol")
              .encode("shape", "smooth")
              .style("lineWidth", 2.5)
              .label({
                text: "",
                transform: [{ type: "overlapDodgeY" }],
                style: {
                  fontSize: 8,
                },
              });

            chart.render();
          } else if (res.data.code == 401 || res.data.code == 403) {
            this.$notify.error({
              title: "错误",
              message: "账号无权限，无法操作",
            });
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    // 请求 CPLE趣宝盒上链数据
    PledgeAxios(key) {
      showLoading();
      const opt = {
        url: reqUrl.getPledgeCheckStatistics,
        method: "POST",
        params: JSON.stringify({
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime:
            key == "all" ? this.search.date[0] : this.search.model.boxdate6[0],
          endTime:
            key == "all" ? this.search.date[1] : this.search.model.boxdate6[1],
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 401 || res.data.code == 403) {
            this.$notify.error({
              title: "错误",
              message: "账号无权限，无法操作",
            });
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.data6 = res.data.data;
            this.isNotActive = true;
            hideLoading();
            const chart = new Chart({
              container: "container6",
              theme: "classic",
              autoFit: true,
              paddingBottom: 80,
              paddingRight: 60,
              paddingLeft: 60,
            });

            chart
              .line()
              .data(this.data6)
              .transform({ type: "groupX" })
              .encode("x", (d) => this.format_time_date_compare(d.checkDate))
              .encode("y", "countNum")
              .axis("y", { title: "" })
              .encode("color", "symbol")
              .encode("shape", "smooth")
              .style("lineWidth", 2.5)
              .label({
                text: "",
                transform: [{ type: "overlapDodgeY" }],
                style: {
                  fontSize: 8,
                },
              });

            chart.render();
          }
          hideLoading();
        },
        errFunc: (err) => {
          if (err == "Error: Request failed with status code 401") {
            this.$notify.error({
              title: "错误",
              message: "账号无权限，无法操作，请重新登录",
            });
          }
          hideLoading();
        },
      };
      request(opt);
    },

    // 详情-RMB
    detailRMBAxios() {
      showLoading();
      const opt = {
        url: reqUrl.getBalanceHistory,
        method: "POST",
        params: JSON.stringify({
          bizId: this.searchType || "0",
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime: this.search.model.boxdate3[0] || "",
          endTime: this.search.model.boxdate3[1] || "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.RMBData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.RMBData.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    // 详情-转出额度
    detailQuotaAxios() {
      showLoading();
      const opt = {
        url: reqUrl.getQuotaHistory,
        method: "POST",
        params: JSON.stringify({
          bizId: this.searchType || "0",
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime: this.search.model.boxdate5[0] || "",
          endTime: this.search.model.boxdate5[1] || "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.QuotaData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.QuotaData.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    // 详情-cple积分
    detailCplescoreAxios() {
      showLoading();
      const opt = {
        url: reqUrl.getCpleHistory,
        method: "POST",
        params: JSON.stringify({
          bizId: "0",
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime: this.search.model.boxdate4[0] || "",
          endTime: this.search.model.boxdate4[1] || "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.cpleScoreData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.cpleScoreData.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    // 详情-推广用户数据
    detailUserAxios() {
      showLoading();
      const opt = {
        url: reqUrl.getCheckUserDetail,
        method: "POST",
        params: JSON.stringify({
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime: this.search.model.boxdate2[0] || "",
          endTime: this.search.model.boxdate2[1] || "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.userData = res.data.data;
            this.userData.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    // 查看详情
    handleDetail(key) {
      if (key == "rmb") {
        this.RMBVisible = true;
        this.detailRMBAxios();
      } else if (key == "quota") {
        this.QuotaVisible = true;
        this.detailQuotaAxios();
      } else if (key == "cpleScore") {
        this.cpleScoreVisible = true;
        this.detailCplescoreAxios();
      } else if (key == "user") {
        this.UserVisible = true;
        this.detailUserAxios();
      }
    },

    exportToExcel(type) {
      if (type == "rmb") {
        console.log(this.RMBData, "RMBData");
        const arr = this.RMBData.map((item, index) => {
          let isIncome = item.isIncome ? "+ " : "- ";
          return {
            序号: index + 1,
            标题: item.title,
            账单时间: item.createTime,
            金额: isIncome + item.amount,
            手续费: item.fee,
            总额: item.amountTotal,
            交易后金额: item.afterAmount,
          };
        });
        const sheet = xlsx.utils.json_to_sheet(arr);
        const book = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(book, sheet, "sheet1");
        xlsx.writeFile(book, "RMB数据详情.xlsx");
      } else if (type == "cpleScore") {
        const arr = this.cpleScoreData.map((item, index) => {
          let isIncome = item.isIncome ? "+ " : "- ";
          return {
            序号: index + 1,
            标题: item.title,
            账单时间: item.createTime,
            金额: isIncome + item.amount,
            手续费: item.fee,
            总额: item.amountTotal,
            交易后金额: item.afterAmount,
          };
        });
        const sheet = xlsx.utils.json_to_sheet(arr);
        const book = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(book, sheet, "sheet1");
        xlsx.writeFile(book, "CPLE积分数据详情.xlsx");
      } else if (type == "quota") {
        const arr = this.QuotaData.map((item, index) => {
          let isIncome = item.isIncome ? "+ " : "- ";
          return {
            序号: index + 1,
            标题: item.title,
            账单时间: item.createTime,
            金额: isIncome + item.amount,
            手续费: item.fee,
            总额: item.amountTotal,
            交易后金额: item.afterAmount,
          };
        });
        const sheet = xlsx.utils.json_to_sheet(arr);
        const book = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(book, sheet, "sheet1");
        xlsx.writeFile(book, "转出额度数据详情.xlsx");
      } else if (type == "user") {
        const arr = this.userData.map((item, index) => {
          let userStatus =
            item.userStatus == "0"
              ? "注销"
              : item.userStatus == "1"
              ? "正常"
              : item.userStatus == "2"
              ? "封禁"
              : "";
          return {
            序号: index + 1,
            手机号: item.phone,
            姓名: item.realName,
            状态: userStatus + item.userStatus,
            注册时间: item.regTime,
            实名时间: item.authTime,
            车主认证时间: item.driverTime,
            注销时间: item.cancelTime,
          };
        });
        const sheet = xlsx.utils.json_to_sheet(arr);
        const book = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(book, sheet, "sheet1");
        xlsx.writeFile(book, "推广用户数据详情.xlsx");
      }
    },

    // 详情分页
    handlePageChange(data, index) {
      if (index == "rmb") {
        showLoading();
        const opt = {
          url: reqUrl.getBalanceHistory,
          method: "POST",
          params: JSON.stringify({
            bizId: this.searchType || "0",
            phone: this.search.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            startTime: this.search.model.boxdate3[0] || "",
            endTime: this.search.model.boxdate3[1] || "",
            pageNum: data.pageIndex,
            pageSize: data.pageSize,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            } else if (res.data.code == 0) {
              this.RMBData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              this.currentPage = data.pageIndex;
              this.RMBData.map((item) => {
                this.changeItem(item);
              });
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            hideLoading();
          },
        };
        request(opt);
      } else if (index == "quota") {
        showLoading();
        const opt = {
          url: reqUrl.getQuotaHistory,
          method: "POST",
          params: JSON.stringify({
            bizId: this.searchType || "0",
            phone: this.search.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            startTime: this.search.model.boxdate5[0] || "",
            endTime: this.search.model.boxdate5[1] || "",
            pageNum: data.pageIndex,
            pageSize: data.pageSize,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            } else if (res.data.code == 0) {
              this.QuotaData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              this.currentPage = data.pageIndex;
              this.QuotaData.map((item) => {
                this.changeItem(item);
              });
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            hideLoading();
          },
        };
        request(opt);
      } else if (index == "cpleScore") {
        showLoading();
        const opt = {
          url: reqUrl.getCpleHistory,
          method: "POST",
          params: JSON.stringify({
            bizId: "0",
            phone: this.search.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            startTime: this.search.model.boxdate4[0] || "",
            endTime: this.search.model.boxdate4[1] || "",
            pageNum: data.pageIndex,
            pageSize: data.pageSize,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            } else if (res.data.code == 0) {
              this.cpleScoreData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              this.currentPage = data.pageIndex;
              this.cpleScoreData.map((item) => {
                this.changeItem(item);
              });
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            hideLoading();
          },
        };
        request(opt);
      }
    },

    // 详情弹窗搜索类别
    handleChangeSearchType(key) {
      if (key == "rmb") {
        this.detailRMBAxios();
      } else if (key == "quota") {
        this.detailQuotaAxios();
      } else if (key == "cpleScore") {
        this.detailCplescoreAxios();
      }
    },

    changeItem(item) {
      item.createTime = format_time_date(item.createTime);
      item.regTime = format_time_date(item.regTime);
      item.authTime = format_time_date(item.authTime);
      item.driverTime = format_time_date(item.driverTime);
      item.cancelTime = format_time_date(item.cancelTime);
      if (item.AuditStatusEnum == "0") {
        item.AuditStatusEnum = "";
      } else if (item.AuditStatusEnum == "1") {
        item.AuditStatusEnum = "待审核";
      } else if (item.AuditStatusEnum == "2") {
        item.AuditStatusEnum = "审核通过";
      } else if (item.AuditStatusEnum == "3") {
        item.AuditStatusEnum = "审核拒绝";
      }
    },

    getDefaultTime() {
      const start = `${dayjs().subtract(179, "day").format("YYYY-MM-DD")}`;
      const end = `${dayjs().subtract(0, "day").format("YYYY-MM-DD")}`;
      this.search.date = [start, end];
      this.form.beginDate = start;
      this.form.endDate = end;
    },

    // 获取枚举类型
    getType() {
      const opt = {
        url: reqUrl.getBillTradeEnum,
        method: "POST",
        resFunc: (res) => {
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            this.RMBoptions.option = res.data.data;
            this.CPLEoptions.option = res.data.data;
            this.Quotaoptions.option = res.data.data;
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
        },
      };
      request(opt);
    },

    // RMB操作
    handleRMBOperation(i) {
      if (i == 1) {
        this.RMBdialogFormVisibleAdd = true;
      } else {
        this.RMBdialogFormVisibleSubtract = true;
      }

      this.getType();
    },

    handleRMBOperationTrue(type) {
      showLoading();
      const opt2 = {
        url: reqUrl.modifyBalance,
        method: "POST",
        params: JSON.stringify({
          billType: this.RMBoptions.RMBSelectValue,
          fee: this.RMBoptions.fee,
          amount: this.RMBoptions.amount,
          isIncome: type ? true : false,
          detail: this.RMBoptions.detail,
          auditor: localStorage.getItem("userName"),
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
        }),
        resFunc: (res) => {
          if (res.data.code != 0) {
            console.log(1234);
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            if (type) {
              this.RMBdialogFormVisibleAdd = false;
            } else {
              this.RMBdialogFormVisibleSubtract = false;
            }
            this.$message({
              message: "操作成功",
              type: "success",
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          if (err == "Error: Request failed with status code 403") {
            this.$notify.error({
              title: "错误",
              message: "当前账号权限不足",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: err,
            });
          }
          hideLoading();
        },
      };
      request(opt2);
    },

    // CPLE积分操作
    handleCPLEOperation(i) {
      if (i == 1) {
        this.CPLEdialogFormVisibleAdd = true;
      } else {
        this.CPLEdialogFormVisibleSubtract = true;
      }

      this.getType();
    },

    handleCPLEOperationTrue(type) {
      showLoading();
      const opt2 = {
        url: reqUrl.modifyCPLE,
        method: "POST",
        params: JSON.stringify({
          billType: this.CPLEoptions.CPLESelectValue,
          amount: this.CPLEoptions.amount,
          isIncome: type ? true : false,
          detail: this.CPLEoptions.detail,
          auditor: localStorage.getItem("userName"),
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
        }),
        resFunc: (res) => {
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            if (type) {
              this.CPLEdialogFormVisibleAdd = false;
            } else {
              this.CPLEdialogFormVisibleSubtract = false;
            }
            this.$message({
              message: "操作成功",
              type: "success",
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          if (err == "Error: Request failed with status code 403") {
            this.$notify.error({
              title: "错误",
              message: "当前账号权限不足",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: err,
            });
          }
          hideLoading();
        },
      };
      request(opt2);
    },

    // 转出额度操作
    handleQuotaOperation(i) {
      if (i == 1) {
        this.QuotadialogFormVisibleAdd = true;
      } else {
        this.QuotadialogFormVisibleSubtract = true;
      }

      this.getType();
    },

    handleQuotaOperationTrue(type) {
      showLoading();
      const opt2 = {
        url: reqUrl.modifyQuota,
        method: "POST",
        params: JSON.stringify({
          billType: this.Quotaoptions.QuotaSelectValue,
          fee: this.Quotaoptions.fee,
          amount: this.Quotaoptions.amount,
          isIncome: type ? true : false,
          detail: this.Quotaoptions.detail,
          auditor: localStorage.getItem("userName"),
          phone: this.search.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
        }),
        resFunc: (res) => {
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            if (type) {
              this.QuotadialogFormVisibleAdd = false;
            } else {
              this.QuotadialogFormVisibleSubtract = false;
            }
            this.$message({
              message: "操作成功",
              type: "success",
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          if (err == "Error: Request failed with status code 403") {
            this.$notify.error({
              title: "错误",
              message: "当前账号权限不足",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: err,
            });
          }
          hideLoading();
        },
      };
      request(opt2);
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;

  .el-input {
    width: 300px;
  }

  ::v-deep .el-input__inner {
    border-radius: 13px;
  }

  ::v-deep .el-range-editor.el-input__inner {
    margin-right: 10px;
  }

  .el-select {
    width: 240px;
    margin-right: 10px;
  }
}

.active {
  display: none;
}
.data_compare_g2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .data_compare_g2_model {
    width: 100%;
    height: 370px;
    border-radius: 15px;
    background: #fff;
    margin-top: 10px;

    #container,
    #container2,
    #container3,
    #container4,
    #container5,
    #container6 {
      width: 100%;
      height: 330px;
    }

    .container_empty {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "PingFangSC-light", "Hiragino Sans GB", "Microsoft YaHei",
        "WenQuanYi Micro Hei", sans-serif;
      color: #666;
    }

    .data_compare_g2_model_son {
      width: 90%;
      margin: 5px auto;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      ::v-deep .el-range-editor.el-input__inner {
        width: 293px;
        border-radius: 15px;
      }

      ::v-deep .el-dialog {
        width: 90%;
        margin-top: 6vh !important;
      }
    }
  }
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #e6e7e7;
  border-color: #d3d4d6;
  color: #7c7d7e;
}
</style>
